import { useState } from 'react';
import { loginUser } from '../services/apiService';

// pegar dados do usuário logado
const useAuth = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const user = localStorage.getItem('user');
        return !!user;
    });
    const [user, setUser] = useState(() => {
        const user = localStorage.getItem('user');
        if (!user)
            return {};

        try {
            return JSON.parse(user);
        } catch (error) {
            localStorage.removeItem('user');
            return {};
        }
    });
    const login = async (credentials) => {
        setLoading(true);
        setError(null);
        try {
            const data = await loginUser(credentials);
            localStorage.setItem('user', JSON.stringify(data));
            setIsLoggedIn(true);
            setUser(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        setUser(null);
        setIsLoggedIn(false);
        localStorage.removeItem('user');
    };

    const updateNotifications = (notifications) => {
        setUser((prevUser) => {
            const updatedUser = { ...prevUser, notifications };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            return updatedUser;
        });
    };

    // Função para checar o status da sessão do usuário
    const checkAuthStatus = () => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const currentTime = new Date().getTime();
            if (parsedUser.valid_until && currentTime > parsedUser.valid_until) {
                logout();
            } else {
                setIsLoggedIn(true);
                setUser(parsedUser);
            }
        } else {
            logout();
        }
    };

    return { loading, error, isLoggedIn, setIsLoggedIn, user, login, logout, setUser, checkAuthStatus, updateNotifications };
};

export default useAuth;
